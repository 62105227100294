// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageInfo from '../../../../package.json';

export const environment = {
  version: packageInfo.version,
  production: true,
  apiBaseUrl: 'https://urv3iuqh23.execute-api.eu-central-1.amazonaws.com/prod',
  s3BucketUrl: 'https://medtoday-stack-ch-stage-appassetsandcontentbucke-jrpua5f9gb2y.s3.eu-central-1.amazonaws.com/',
  reactAppScriptUrl: 'https://medtoday-react.de/api/embed-talks-app.js',
  surveyMonkeyScriptUrl: '',
  name: 'MedToday',
  language: 'ch',
  domain: 'ch',
  payRexxDomain: 'medtalks-switzerland',
  cognito: {
    cognitoUserPoolId: 'eu-central-1_LRg9O3qYG',
    cognitoAppClientId: '4krf2hv90mb391um45ik4mlemm',
    cognitoIdentityPoolId: 'eu-central-1:ffe3ee84-ee17-4ec7-97e5-9135bbd0f4da',
    appInstanceArn: 'arn:aws:chime:us-east-1:411085519349:app-instance/c8e06bf6-9a5a-48e6-9c19-743108631106',
    region: 'eu-central-1',
    oauth: {
      domain: 'medtoday-dev.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://stage.medtoday.ch/',
      redirectSignOut: 'https://stage.medtoday.ch/',
      responseType: 'code'
    }
  },
  twitterWallId: '19994',
  youtubeApiKey: '',
  twitterProfileUrl: 'https://twitter.com/medtodayswiss',
  linkedInProfileUrl: 'https://www.linkedin.com/company/medtoday-switzerland/?viewAsMember=true',
  instagramProfileUrl: 'https://www.instagram.com/medtodaygermany/',
  medboardsUrl: 'https://medboards.medtoday.de/',
  medBoardsApiUrl: 'https://medboards.medtoday.de/api',
  hasNewsletter: true,
  megaMenuFilterExclude: '',
  knowledgeSeriesVideosIds: []
};

export const imagePaths = {
  logos: 'logo/', // sponsors, patrons, website logos etc.
  thumbnails: 'thumbnail/', // video thumbnails, feature blocks
  avatars: 'avatar/', // profile pictures
  images: 'image/', // general images
  pdfs: 'pdf/'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
